import React from "react";
import Layout from "../components/layout";
import { Container, Row } from "reactstrap";
import Link from "../components/link";
import styled from "styled-components";
import useSiteMetadata from "../hooks/use-site-config";
import Footer from "../components/footer"
import "../assets/stylesheets/layout.scss"
import {ThemeProvider} from 'styled-components'
import theme from '../assets/stylesheets/theme'
import { Helmet } from "react-helmet"

import {
  Collapse,
  Navbar,
  NavbarToggler,
} from 'reactstrap';

const Text404 = styled.div`
  margin: 4rem auto;

  .text-bold {
    font-weight: bold !important;
}

.text-info {
    color: #36244e;
}
p {
    margin: 0 0 10.5px;
}
`;
const Link404 = styled(Link)`
  color: #932a7e;
  font-weight: 700;
`;

export default function PageNotFound(props) {
  const { siteTitle } = useSiteMetadata();
  const title = "Política de privacidad";
  return (
    <ThemeProvider theme={theme}>
       <Helmet  title={title}>
          <meta charSet="utf-8" />
        </Helmet>
        <Navbar color="light" light expand="md">
      <Container>
        <div  className="navbar-brand">
          <span className="nameOne">PORTAL </span>
          <span className="nameTwo">PUNTANO</span>
        </div>
      </Container>
    </Navbar>
      <main >

      <Container>
        <Row>
          <Text404>
            <h1 class="text-center">Política de privacidad:</h1>
            <p>
              El presente documento tiene por objeto establecer la Política de
              Privacidad de las aplicaciones denominadas "Portal Puntano" , "CCC
              3.0", "NOC 3.0", "Mandalas ATM", "Uñas ATM", "Recetas ATM", "REM",
              "Mi pr&oacute;ximo Colectivo", "Wifi 3.0", "Pueblo Sin Chagas",
              "Alarma Puntana", "Barrio Sin Dengue", "Barrio Sin Dengue
              Profesional", "Agentes Sanitarios 3.0", "B&uacute;squeda
              Inteligente", "Registro de Asistencia Inclusi&oacute;n Social",
              "Mi Clima SL", "Vecinos en Alerta" y "Asistencia 3.0". Deseamos
              mantener y proteger la privacidad online y queremos que los
              usuarios comprendan cómo recopilamos, utilizamos y presentamos su
              información personal. Las aplicaciones son titularidad del
              Gobierno de San Luis. El hecho de utilizar alguna de estas
              aplicaciones supone la aceptación de la presente Política de
              Privacidad.
            </p>
            <p class="text-bold text-info" > Privacidad de los datos personales:</p>
            <p> Sus datos personales le corresponden sólo a usted y este sitio web
              es responsable de no revelar ninguna clase de información que le
              pertenezca (como email, números de ip, etc.), salvo su expresa
              autorización o fuerzas de naturaleza mayor de tipo legal que lo
              involucren, como hackeos o suplantaciones.
            </p>
            <p class="text-bold text-info">Seguridad de su información personal: </p>
            <p>Este sitio web se hace responsable de velar por su seguridad, por
              la privacidad de su información y por el respeto a sus datos, de
              acuerdo con las limitaciones que la actual Internet nos provee,
              siendo conscientes que no estamos excluí­dos de sufrir algún
              ataque por parte de crackers o usuarios malintencionados que
              ejerzan la delincuencia informática.
            </p>
            <p class="text-bold text-info">Obtención de su información:</p>
            <p> Todos sus datos personales consignados en este sitio son
              suministrados por usted mismo, haciendo uso entero de su libertad.
              La información aquí almacenada sólo comprende datos básicos
              ingresados mediante formularios de contacto, comentarios u otros
              similares.
            </p>
            <p class="text-bold text-info" > Uso de la información: </p>
            <p> Al proporcionarnos sus datos personales, estando de acuerdo con la
              Polí­tica de Privacidad aquí­ consignada, nos autoriza para el
              siguiente uso de su información: a- para el fin mismo por lo cual
              se ha suministrado;
            </p>
            <p class="text-bold text-info" >Modificaciones a nuestras Polí­ticas de Privacidad: </p>
            <p> El sitio web se reserva el derecho de modificar, rectificar,
              alterar, agregar o eliminar cualquier punto del presente escrito
              en cualquier momento y sin previo aviso, siendo su responsabilidad
              el mantenerse informado del mismo para una adecuada administración
              de su información.
            </p>
          </Text404>
        </Row>
      </Container>

      </main>
      <Footer/>
    </ThemeProvider>
  );
}
